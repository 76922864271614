import React from 'react'
import { GatsbySeo } from 'gatsby-plugin-next-seo'
import { Helmet } from 'react-helmet'
import { useStaticQuery, graphql } from 'gatsby'

// Icon
import favicon from 'img/favicon.png'

const removeHTML = (content: string) => {
  let newContent = content

  if (typeof document !== 'undefined') {
    const titleHtml = newContent
    const div = document.createElement('div')
    div.innerHTML = titleHtml
    newContent = div.innerText
  }

  return newContent
}

interface SEOProps {
  seo: any
}

const SEO: React.FC<SEOProps> = ({ seo }) => {
  const query = useStaticQuery<GatsbyTypes.SeoBannerQuery>(graphql`
    query SeoBanner {
      file(name: { eq: "seo_banner" }) {
        publicURL
      }
    }
  `)

  const image: any = seo.opengraphImage
    ? {
        url: seo.opengraphImage.sourceUrl,
        width: 800,
        height: 600,
        alt: seo.opengraphTitle,
      }
    : {
        url: query.file?.publicURL,
        width: 800,
        height: 600,
        alt: 'SenseAnywhere',
      }

  return (
    <>
      <GatsbySeo
        language="nl"
        title={removeHTML(seo.title) || ''}
        description={removeHTML(seo.metaDesc) || ''}
        canonical={seo.canonical || ''}
        openGraph={{
          type: seo.opengraphTitle || '',
          url: seo.opengraphUrl || '',
          title: seo.opengraphTitle || '',
          description: seo.opengraphDescription || '',
          images: [image],
        }}
      />
      <Helmet>
        <script
          id="Cookiebot"
          src="https://consent.cookiebot.com/uc.js"
          data-cbid="7b372ac3-6c01-40c9-91b1-06167563e391"
          data-blockingmode="auto"
          type="text/javascript"
        />
        {/* <!--Start of Tawk.to Script--> */}
          <script type="text/javascript">
            {`
            var Tawk_API=Tawk_API||{}, Tawk_LoadStart=new Date();
            (function(){
            var s1=document.createElement("script"),s0=document.getElementsByTagName("script")[0];
            s1.async=true;
            s1.src='https://embed.tawk.to/63e3a7a8474251287912309c/1gookdqv5';
            s1.charset='UTF-8';
            s1.setAttribute('crossorigin','*');
            s0.parentNode.insertBefore(s1,s0);
            })();
            `}
          </script>
          {/* <!--End of Tawk.to Script--> */}
        <link rel="icon" href={favicon} />
      </Helmet>
    </>
  )
}

export default SEO
