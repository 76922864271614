/* eslint-disable no-underscore-dangle */
import React from 'react'

// Lazy loading
import loadable from '@loadable/component'
import { log } from 'console'

interface FlexContentProps {
  prefix?: string
  title?: string
  fields: any
  location?: any
}

interface FlexerProps {
  [key: string]: any
}

interface ComponentProps {
  fields: any
  location?: any
  title?: string
}

const FlexContentHandler: React.FC<FlexContentProps> = ({
  prefix = 'page_Flexcontent',
  title = '',
  fields,
  location = {},
}) => {

  const flexers: FlexerProps = {
    [`${prefix}_Flex_Banner`]: loadable(
      () => import('components/flex/Banner/BannerShell')
    ),
    [`${prefix}_Flex_Form`]: loadable(() => import('components/flex/Form')),
    [`${prefix}_Flex_Text`]: loadable(
      () => import('components/flex/Text/Text')
    ),
    [`${prefix}_Flex_TextWithImage`]: loadable(
      () => import('components/flex/Text/TextWithImage')
    ),
    [`${prefix}_Flex_TextWithImageAndVideo`]: loadable(
      () => import('components/flex/Text/TextWithImageAndVideo')
    ),
    [`${prefix}_Flex_TextWithApplications`]: loadable(
      () => import('components/flex/Text/TextWithApplications')
    ),
    [`${prefix}_Flex_TextWithPhrase`]: loadable(
      () => import('components/flex/Text/TextWithPhrase')
    ),
    [`${prefix}_Flex_Quote`]: loadable(
      () => import('components/flex/Quote/QuoteShell')
    ),
    [`${prefix}_Flex_UspWithBackgroundImage`]: loadable(
      () => import('components/flex/Usp/UspWithBackgroundImage')
    ),
    [`${prefix}_Flex_UspWithVideo`]: loadable(
      () => import('components/flex/Usp/UspWithVideo')
    ),
    [`${prefix}_Flex_UspWithVideoLanguages`]: loadable(
      () => import('components/flex/Usp/UspWithVideoLanguages')
    ),
    [`${prefix}_Flex_Video`]: loadable(() => import('components/flex/Video')),
    [`${prefix}_Flex_UspProduct`]: loadable(
      () => import('components/flex/Usp/UspProduct')
    ),
    [`${prefix}_Flex_ProductFeatures`]: loadable(
      () => import('components/flex/Product/ProductFeatures')
    ),
    [`${prefix}_Flex_ProductRoute`]: loadable(
      () => import('components/flex/Product/ProductRoute')
    ),
    [`${prefix}_Flex_ProductWithUsps`]: loadable(
      () => import('components/flex/Product/ProductWithUsps')
    ),
    [`${prefix}_Flex_Certification`]: loadable(
      () => import('components/flex/Certification/Certification')
    ),
    [`${prefix}_Flex_CtaWithImage`]: loadable(
      () => import('components/flex/CtaWithImage')
    ),
    [`${prefix}_Flex_TeamWithEmployees`]: loadable(
      () => import('components/flex/TeamWithEmployees')
    ),
    [`${prefix}_Flex_Applications`]: loadable(
      () => import('components/flex/Application/Applications')
    ),
    [`${prefix}_Flex_ApplicationsSmall`]: loadable(
      () => import('components/flex/Application/ApplicationsSmall')
    ),
    [`${prefix}_Flex_FindReseller`]: loadable(
      () => import('components/flex/Reseller/FindReseller')
    ),
    [`${prefix}_Flex_FindResellerForm`]: loadable(
      () => import('components/flex/Reseller/FindResellerForm')
    ),
    [`${prefix}_Flex_Clients`]: loadable(
      () => import('components/flex/Clients')
    ),
    [`${prefix}_Flex_TextImageSecond`]: loadable(
      () => import('components/flex/Text/TextImageSecond')
    ),
    [`${prefix}_Flex_TextWithBigImage`]: loadable(
      () => import('components/flex/Text/TextWithBigImage')
    ),
    [`${prefix}_Flex_TextWithBackgroundImage`]: loadable(
      () => import('components/flex/Text/TextWithBackgroundImage')
    ),
    [`${prefix}_Flex_BackgroundImage`]: loadable(
      () => import('components/flex/BackgroundImage')
    ),
    [`${prefix}_Flex_TextWithBigIcon`]: loadable(
      () => import('components/flex/Text/TextWithBigIcon')
    ),
    [`${prefix}_Flex_CertificationSmall`]: loadable(
      () => import('components/flex/Certification/CertificationSmall')
    ),
    [`${prefix}_Flex_About`]: loadable(() => import('components/flex/About')),
    [`${prefix}_Flex_Products`]: loadable(
      () => import('components/flex/Product/Products')
    ),
    [`${prefix}_Flex_Modules`]: loadable(
      () => import('components/flex/Module/Modules')
    ),
    [`${prefix}_Flex_Accessories`]: loadable(
      () => import('components/flex/Accessoire/Accessoires')
    ),
    [`${prefix}_Flex_Contact`]: loadable(
      () => import('components/flex/Contact')
    ),
    [`${prefix}_Flex_HeroWithText`]: loadable(
      () => import('components/flex/HeroWithText')
    ),
    [`${prefix}_Flex_BlogOverview`]: loadable(
      () => import('components/flex/Blog/BlogShell')
    ),
    [`${prefix}_Flex_VacancyOverview`]: loadable(
      () => import('components/flex/Vacancy/VacancyOverview')
    ),
    [`${prefix}_Flex_ApplicationIframe`]: loadable(
      () => import('components/flex/IframeVideo')
    ),
    [`${prefix}_Flex_BlogStory`]: loadable(
      () => import('components/flex/Blog/BlogStory')
    ),
    [`${prefix}_Flex_VacancyText`]: loadable(
      () => import('components/flex/Vacancy/VacancyText')
    ),
    [`${prefix}_Flex_Gallery`]: loadable(
      () => import('components/flex/Gallery')
    ),
    [`${prefix}_Flex_Videos`]: loadable(
      () => import('components/flex/Videos')
    ),
    [`${prefix}_Flex_Fragment`]: loadable(
      () => import('components/flex/Fragment')
    ),
  }

  if (!fields.flex) {
    return null
  }

  return (
    <div>
      {fields.flex.map((section: any, index: number) => {
        if (!section.fieldGroupName || !flexers[section.fieldGroupName]) {
          return null
        }

        const Component: React.FC<ComponentProps> =
          flexers[section.fieldGroupName]

        return (
          <Component
            // eslint-disable-next-line react/no-array-index-key
            key={`${location ? location.pathname : 'flex'}-${index}`}
            fields={section}
            title={title}
            location={location}
          />
        )
      })}
    </div>
  )
}

export default FlexContentHandler
